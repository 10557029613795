<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="text-xs-left" style="padding: 0px !important; min-width: 70px;">
      <span>{{ props.item.id }}</span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px;">
      <strong v-text="props.item.name"></strong>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px;">
      <div v-if="props.item.type_info" style="text-align: center;">
        <v-icon :color="props.item.type_info.color">
          {{ props.item.type_info.icon }}
        </v-icon>
        <br>
        <strong v-text="props.item.type_info.name"></strong>
      </div>
    </td>
    <!-- <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px;">
      <div v-if="props.item.type == 8 && props.item.integration_id" style="text-align: center;">
        <v-btn outline link :to="{name: 'talk-flow', params: {id: props.item.integration_id}}">
          <span>acessar</span>
        </v-btn>
      </div>
    </td> -->
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'TalkMenus',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>